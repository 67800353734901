import React from "react";
import "./question.css";

function QuestionWidget() {
  return (
    <a target="_blank" href="https://t.me/NvolumeSupport_bot">
      <div className="question_widget">
        <p>?</p>
      </div>
    </a>
  );
}

export default QuestionWidget;
