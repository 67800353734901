export const ru_locale = {
  // navbar
  active: "Активный",
  nav_menu_1: "Дашборд",
  nav_menu_2: "Статистика",
  nav_menu_3: "Алгоритм",
  nav_menu_4: "Тарифы",
  nav_menu_5: "Транзакции",
  nav_menu_6: "Настройки",
  nav_menu_7: "Редактировать профиль",
  nav_menu_8: "Безопасность",
  nav_menu_9: "Подключение API",
  nav_menu_10: "Изучить",
  nav_menu_11: "База знаний",
  nav_menu_12: "Политика конфиденциальности",
  nav_menu_13: "Клиентское соглашение",
  nav_menu_14: "Реферальная программа",
  nav_menu_15: "Выйти",
  // reviews
  rev_1: "Обзор",
  rev_2: "Баланс",
  rev_3: "Основной аккаунт",
  rev_4: "PnL за сегодня",
  rev_5: "История ордеров",
  rev_6: "Здесь отображается список последних 10 ордеров",
  rev_7: "Нет истории ордеров",
  tariff_title: "Тарифный план",

  trial_period: "7 дней",
  paid_period: "30 дней",
  day_90: "90 дней",
  last_180: "Последний 180 дней",

  remaining_days: "Осталось:",
  add_30_days: "Продлить",
  trial_period_title: "Пробный период",
  free_trial: "7 дней тестовый период",
  activate_trial:
    "Активируйте тестовый период уже сегодня и получите доступ к широкому спектру возможностей.",
  activate: "Активировать",
  trade_open_time: "Время открытия сделки",
  trade_close_time: "Время закрытия сделки",
  buy_price: "Цена покупки",
  sell_price: "Цена продажи",
  position_volume: "Объем позиции",
  profit_or_loss: "Прибыль или убыток",
  faq_title: "Часто задаваемые вопросы",
  faq_description:
    "В нашей базе знаний вы можете найти ответы на самые часто задаваемые вопросы.",
  faq_question_1: "Как &Volume решает, куда инвестировать средства?",
  faq_question_2: "Какая минимальная сумма?",
  faq_question_3: "Могу ли я использовать заёмные деньги?",
  faq_question_4: "Какие комиссии и условия использования?",
  faq_question_5: "Какие гарантии безопасности есть?",
  knowledge_base_link: "Перейти в базу знаний",
  // analysis
  balance_title: "Баланс",
  main_account: "Основной аккаунт",
  pnl_title: "PnL",
  pnl_today: "PnL за сегодня",
  pnl_period_title: "PnL за период",
  pnl_period: "PnL с",
  pnl_main_title: "PnL",
  pnl_by_days_title: "PnL по дням",
  executed_orders_title: "Исполняемые ордера",
  executed_orders_description: "Здесь отображается список открытых ордеров.",
  long_order: "Long",
  short_order: "Short",
  no_open_orders: "Нет открытых ордеров",
  no_order_history: "Нет истории ордеров",
  investment_title: "Выберите уровень риска",
  investments_page_title: {
    main_title: "Выберите уровень риска",
    sub_title:
      "Чем выше уровень риска, тем выше потенциальная доходность, однако вероятность просадки депозита тоже увеличивается, учтите это при выборе",
  },
  conservative: "Консервативный",
  minimumInvestmentAmount: "Минимальная сумма инвестиции",
  riskLevel: "Уровень риска",
  projectedYield: "Прогнозируемая доходность за период",
  low: "Низкий",
  upTo: "до",
  profitabilityDisclaimer:
    "* Доходность рассчитана на основе исторических данных и не является гарантией будущей доходности",
  select: "Выбрать",
  moderate: "Умеренный",
  loremIpsum: "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
  aggressive: "Агрессивный",
  medium: "Средний",
  projectedYieldPeriod: "Прогнозируемая доходность за период",
  high: "Высокий",
  investments: "Алгоритмы",
  activeInvestmentsDescription: "Здесь отображаются активные алгоритмы",
  creationTime: "Время создания",
  investmentMethod: "Метод инвестирования",

  initialAmount: "Начальная сумма",
  usedAmount: "Используемая сумма",
  profitOrLoss: "Прибыль или убыток",
  delete: "Удалить",
  noActiveInvestments: "Нет активных инвестиций",
  faqTitle: "Часто задаваемые вопросы",
  conservativeTitle: "Консервативный",

  maxDrawdown: "Макс. просадка",
  pnl30Days: "Pnl(%) за 30 дней",
  investment: "Депозит",
  min100: "Мин.100 - Макс.3000",
  available: "Доступно",
  totalStopLoss: "Общий стоп-лосс",
  enterMaxAcceptableLoss: "Введите максимально приемлемый убыток",
  ifNetLossReaches: "Если чистый убыток достигнет",
  investmentsWillStop: "инвестиции прекратятся",
  additionalInformation: "Дополнительная информация",
  volumeStopLoss: "В &Volume стоп-лосс это ручной механизм ограничения потерь.",
  volumeStopLossDescription:
    "Вы указываете общий абсолютный уровень потерь в USDT, а не процентный. Например, сумма торгов $1000, вы устанавливаете общий стоп-лосс $200.",
  volumeStopLossExample:
    "В случае, если в какой-либо из сделок криптовалюта аномально снизится (или возрастет) в цене, ваш убыток не превысит $200, а сервис временно перестанет открывать сделки до нормализации рыночных условий.",
  volumeStopLossDefault:
    "Если не установить значение вручную, то по умолчанию общий стоп-лосс составит 50% от депозита на бирже.",
  iHaveReadAndAccept: "Я прочитал(-а) и принимаю",
  termsOfUse: "Условия торговли",
  moderateTitle: "Умеренный",
  transactions: "Транзакции",
  transactionListDescription: "Здесь отображается список транзакций",
  processing: "В обработке",
  error: "Ошибка",
  completed: "Завершён",
  createdAt: "Время создания",
  transactionType: "Тип транзакции",
  commission: "Комиссия",
  transactionAmount: "Сумма транзакции",
  noTransactions: "Нет транзакций",
  rates: "Тарифы",
  selectPlan: "Выберите план",
  paymentDisclaimer:
    "При оплате тарифа платёжный сервис берет комиссию за проведение транзакции. Внимательно указывайте сумму с учётом комиссии.",
  tariffPlanTitle: "Текущий тарифный план",
  trialPlanDuration: "7 дней",
  paidPlanDuration: "30 дней",
  remainingDays: "дней",
  addDaysLink: "Добавить + 30 дней",
  trialPeriodTitle: "Пробный период",
  freePlanDuration: "7 дней бесплатно",
  activateTrialPeriod:
    "Активируйте тестовый период уже сегодня и получите доступ к широкому спектру возможностей.",
  activateLink: "Активировать",
  startPlanTitle: "Стартовый",
  startPlanDescription: "Для новых инвесторов",
  startPlanPrice: "$ 25",
  startPlanPricePerMonth: "/ ежемесячно",
  startPlanTradingLimit: "Лимит суммы в торгах до $400",
  buyButton: "Купить",
  advancedPlanTitle: "Улучшенный",
  advancedPlanDescription: "Для опытных инвесторов",
  advancedPlanPrice: "$ 50",
  advancedPlanPricePerMonth: "/ ежемесячно",
  advancedPlanTradingLimit: "Лимит суммы в торгах до $900",
  professionalPlanTitle: "Продвинутый",
  professionalPlanDescription: "Для профессионалов",
  professionalPlanPrice: "$ 100",
  professionalPlanPricePerMonth: "/ ежемесячно",
  professionalPlanTradingLimit: "Лимит суммы в торгах до $3 000",
  controlTitle: "Не теряйте самоконтроль",
  controlDescription:
    "Инвестиции в торговлю криптовалютой рискованнее, чем традиционные инструменты (вклад в банк, покупка акцией на долгий срок и т.п.), рассчитывайте свои риски и не инвестируйте все свои сбережения.",
  profile: "Профиль",
  security: "Безопасность",
  apiKeys: "Ключи API",
  // profile

  profileImage: "Изображение профиля",
  changeProfileImage: "Вы можете изменить изображение вашего профиля",
  supportedFormats: "Поддерживаются форматы JPG, PNG.",
  maxFileSize: "Максимальный размер файла для загрузки: 10 Мб",
  accountInfo: "Информация об учетной записи",
  changeDataBelow: "Вы можете поменять данные ниже",
  fullName: "Полное имя",
  change: "Изменить",
  name: "имя",
  nickname: "Псевдоним",
  email: "Адрес электронной почты",
  phoneNumber: "Номер телефона",
  changeName: "Изменить имя",
  lastName: "Фамилия",
  firstName: "Имя",
  middleName: "Отчество",
  save: "Сохранить",
  cancel: "Отмена",
  changeUsername: "Изменить псевдоним",
  username: "Псевдоним",
  changePhoneNumber: "Изменить номер телефона",
  changeEmail: "Изменить электронную почту",
  newEmail: "Новая электронная почта",
  confirmNewEmail: "Новая электронная почта ещё раз",
  enterCode: "Введите код",
  codeSentTo: "Мы отправили код на ",
  resendCodeIn: "Отправить повторно ",
  securitySettings: "Настройки безопасности",
  changeSettingsBelow: "Вы можете поменять настройки или отключить их ниже",
  currentPassword: "Текущий пароль",
  changePassword: "Изменить",
  password: "пароль",
  loginHistory: {
    title: "История входов в аккаунт",
    description: "Здесь отображается список последних 10 входов",
    time: "Время",
    location: "Местонахождение",
    ipAddress: "IP-адрес",
  },
  passwordChange: {
    title: "Изменить пароль",
    currentPassword: "Текущий пароль",
    newPassword: "Новый пароль",
    confirmNewPassword: "Подтвердите новый пароль",
    confirm: "Подтвердить",
    cancel: "Отмена",
  },
  emailChange: {
    title: "Изменить электронную почту",
    newEmail: "Новая электронная почта",
    confirmNewEmail: "Новая электронная почта ещё раз",
    confirm: "Подтвердить",
    cancel: "Отмена",
  },
  apiKeyPage: {
    title: "Ключи API",
    addApiKey: "Добавить ключ API",
  },
  apiTable: {
    timeAdded: "Время добавления",
    status: "Статус",
    name: "Название",
    exchange: "Биржа",
    action: "Действие",
    activeStatus: "Активное",
    edit: "Редактировать",
    delete: "Удалить",
    noApiConnected: "Нет подключенных API.",
  },
  apiMobile: {
    timeAdded: "Время добавления",
    exchange: "Биржа",
    noApiConnected: "Нет подключенных API.",
  },
  apiModal: {
    title: "Добавить ключ API",
    name: "Название",
    exchange: "Биржа",
    publicKey: "Публичный ключ",
    privateKey: "Приватный ключ",
    addButton: "Подключить",
    cancelButton: "Отмена",
  },
  apiEditModal: {
    title: "Редактировать ключ API",
    publicKey: "Публичный ключ",
    privateKey: "Приватный ключ",
    saveButton: "Сохранить",
    deleteButton: "Удалить",
    cancelButton: "Отмена",
  },
  faqBlock: {
    knowledgeBase: "База знаний",
    frequentlyAskedQuestions: "Часто задаваемые вопросы",
  },
  baseQuestionsColumn: {
    connection: "Подключение",
    registration: "Регистрация биржевого аккаунта",
    registrationDescription:
      "Узнайте, как зарегистрироваться и пройти верификацию на биржах-партнерах",
    api: "API",
    apiDescription: "Инструкция по созданию API-ключей и подключению к &Volume",
    investments: "Инвестиции",
    investmentsDescription: "Выбор и подключение методов инвестиций",
    buySellCrypto: "Покупка и продажа криптовалюты",
    topupMethods: "Способы пополнения",
    topupMethodsDescription: "Способы и сервисы пополнения биржевого аккаунта",
    p2pTrading: "P2P-торговля",
    p2pTradingDescription:
      "Узнайте, как использовать P2P для пополнения и вывода средств с биржевого аккаунта",
  },
  baseQuestionsColumn2: {
    investments: "Инвестиции",
    differences: "Различия",
    differencesDescription: "Сделайте правильный выбор и заработайте больше",
    statistics: "Статистика",
    statisticsDescription: "Открытая статистика результатов инвестиций &Volume",
    risks: "Риски",
    risksDescription:
      "Не теряйте самоконтроль. Криптовалюты — это рискованная инвестиция с высокой волатильностью",
    rewardsReferrals: "Награды и рефералы",
    partners: "Партнеры",
    partnersDescription: "Узнайте больше о партнерской программе &Volume",
    invitation: "Приглашение",
    invitationDescription: "Приглашайте друзей и зарабатывайте вместе",
    referralRewards: "Реферальные награды",
    referralRewardsDescription:
      "Получайте награды за использование сервиса &Volume вашими рефералами",
  },
  common: {
    inDevelopment: "В разработке",
  },
  baseQuestionsColumn3: {
    tariffs: "Тарифы",
    tradeLimits: "Торговые лимиты",
    tradeLimitsDescription:
      "Узнайте что такое торговые лимиты и как они влияют на торговый результат",
    payment: "Оплата",
    paymentDescription:
      "Руководство по оплате тарифов с помощью сервиса партнера",
    other: "Другое",
    whitepaper: "Whitepaper",
    whitepaperDescription: "Узнайте больше о партнерской программе &Volume",
    clientAgreement: "Клиентское соглашение",
    clientAgreementDescription:
      "Изучите документ, который регулирует отношения между клиентом и компанией",
    privacyPolicy: "Политика конфиденциальности",
    privacyPolicyDescription:
      "Изучите документ, который описывает, как компания собирает, использует и раскрывает информацию о клиентах",
  },
  haveQuestions: "Остались вопросы?",
  readyToHelp: "Мы готовы помочь вам в любое время",
  openChat: "Открыть чат",
  suggestImprovement: "Предложение по улучшению",
  whatWouldYouLikeToImprove: "Что бы вы хотели улучшить в нашем сервисе?",
  suggestIdea: "Предложить идею",
  rightsReserved: "© 2024 &Volume, все права защищены",
  policy: "Политика",
  agreement: "Соглашение",
  registration: {
    title: "Как зарегистрировать личный аккаунт на сайте Binance",
    step1: "1. Перейдите на сайт",
    step2:
      "и нажмите Зарегистрироваться или выберите метод регистрации напрямую: с помощью электронной почты, номера телефона или учетной записи Apple или Google.",
    info1:
      "Внимательно выбирайте тип учетной записи. После регистрации его нельзя будет изменить. Если вы хотите создать аккаунт организации, перейдите на вкладку «Корпоративный аккаунт», где представлено подробное руководство.",
    step3:
      "2. Введите адрес электронной почты / номер телефона. Если вы регистрируетесь на Binance по ссылке друга, укажите реферальный ID (необязательно). Прочитайте и примите Условия использования и Политику конфиденциальности и нажмите Далее.",
    step4:
      "3. На вашу электронную почту или телефон придет код верификации из шести цифр. Введите его в течение 30 минут и нажмите Подтвердить.",
    step5:
      "4. Затем создайте надежный пароль. Пароль должен содержать не менее восьми символов, одну заглавную букву и одну цифру. Нажмите Далее.",
    step6: "5. Поздравляем! Вы создали аккаунт Binance.",
  },
  bybitRegistration: {
    title: "Как зарегистрировать личный аккаунт на сайте Bybit",
    step1: "1. Перейдите на сайт",
    "step1.continuation":
      "и нажмите Зарегистрироваться или выберите метод регистрации напрямую: с помощью электронной почты, номера телефона или учетной записи Apple или Google.",
    step2:
      "2. Введите адрес электронной почты / номер телефона. Если вы регистрируетесь на Bybit по ссылке друга, укажите реферальный ID (необязательно). Прочитайте и примите Условия использования и Политику конфиденциальности и нажмите Далее.",
    step3:
      "3. На вашу электронную почту или телефон придет код верификации из шести цифр. Введите его в течение 30 минут и нажмите Подтвердить.",
    step4: "4. Поздравляем! Вы создали аккаунт Bybit.",
  },
  knowledgeBase: "База знаний",
  connection: "Подключение",
  registrationbase: "Регистрация биржевого аккаунта",
  published: "Опубликовано",
  dec: "дек",
  year: "г.",
  api: "Апи",
  minToRead: "мин чтения",
  createAPIKey: {
    title: "Как создать API ключ на бирже Binance",
    note: "Примечание:",
    noteContent:
      "Создание API ключа возможно только с помощью ПК или веб-сайта Binance, так как это нельзя сделать через мобильное приложение Binance.",
    step1: "1. Войдите в свой аккаунт Binance и выберите Профиль —",
    apiManagement: "Управление API.",
    preRequirements:
      "Пожалуйста, обратите внимание, что перед созданием API ключа вы должны:",
    enable2FA: "Активировать",
    "2FA": "двухфакторную аутентификацию (2FA)",
    onYourAccount: "на своем аккаунте.",
    depositSpotWallet:
      "Пополнить сумму на вашем спотовом кошельке, чтобы активировать аккаунт.",
    completeVerification: "Пройти",
    verifyIdentity: "проверку личности.",
    goToFutures: "Перейти в деривативы и создать",
    futuresAccount: "фьючерсный аккаунт.",
    step2: "2. Снимите галочку с элементов управления безопасности.",
    step3: "3. Нажмите Создать API.",
    step4: "4. Выберите тип API-ключа, сгенерированный системой Bybit.",
    step5: "5. Введите название подключения API.",
    step6:
      "6. Подтвердите запрос с помощью двухфакторной аутентификации и Passkey.",
    step7: "7. Настройте необходимые параметры.",
    step8: "8. API ключ готов.",
  },
  createAPIKeyBybit: {
    title: "Как создать API ключ на бирже Bybit",
    note: "Примечание:",
    noteContent:
      "Создание API ключа возможно только с помощью ПК или веб-сайта Bybit, так как это нельзя сделать через мобильное приложение Bybit.",
    step1: "1. Войдите в свой аккаунт Bybit и выберите Профиль —",
    api: "API.",
    preRequirements:
      "Пожалуйста, обратите внимание, что перед созданием API ключа вы должны:",
    enable2FA: "Активировать",
    "2FA": "двухфакторную аутентификацию (2FA)",
    onYourAccount: "на своем аккаунте.",
    depositSpotWallet:
      "Внести любую сумму на спотовый кошелек для активации аккаунта.",
    completeVerification: "Пройти процесс",
    verifyIdentity: "проверки личности.",
    step2: "2. Нажмите Создать API.",
    step3: "3. Выберите тип API-ключа, сгенерированный системой Bybit.",
    step4: "4. Укажите данные для API ключа согласно скриншоту.",
    step5:
      "5. Нажмите отправить и подтвердите запрос с помощью двухфакторной аутентификации.",
    step6:
      "6. Сохраните полученный API ключ и API Secret для дальнейшего использования.",
    note2: "Примечание:",
    noteContent2:
      "Полученный API Secret можно сохранить только один раз. Сохраняйте ключи сразу после получения.",
  },
  investmentMethodBase: {
    selection: "Выбор метода инвестирования",
    autoTradeDescription:
      "Мы предлагаем настроить автоматическую торговлю парами криптовалют, выбрав одну из трёх доступных стратегий. Каждая из них адаптирована под различные уровни риска.",
    conservative: "Консервативный",
    conservativeDescription:
      "&Volume придерживается осторожного подхода к выбору моментов для совершения сделок. Используем небольшие доли депозита на каждую операцию, стремясь к минимизации рисков. Ожидайте небольшого числа сделок, не более 2-3 одновременно, чаще всего не более 5 сделок в день. В зависимости от ситуации на рынке, сделок может совсем не быть несколько дней. Продолжительность одной сделки может быть от 24 часов, до нескольких суток.",
    lowRisk: "Минимальный уровень риска.",
    predictedProfit1: "Прогнозируемая прибыльность до ~10% в месяц.",
    recommendedForLowRisk:
      "Рекомендуется для людей с крупными депозитами и низкой готовностью к риску.",
    moderate: "Умеренный",
    moderateDescription:
      "С умеренным подходом, &Volume более активно взаимодействует с рынком, используя больше средств и открывая до 5 - 10 сделок одновременно. Время удержания сделок сокращается, и, хотя количество убыточных сделок может быть выше, стратегия ориентирована на достижение более высокой прибыльности на средней дистанции, по сравнению с консервативной стратегией.",
    moderateRisk: "Умеренный уровень риска.",
    predictedProfit2: "Прогнозируемая прибыльность до ~20% в месяц.",
    recommendedForModerateRisk:
      "Рекомендуется для людей, готовых к умеренному риску.",
    aggressive: "Агресивный",
    aggressiveDescription:
      "Самая прибыльная, но и рискованная стратегия. &Volume использует значительные средства и открывает до 40 сделок одновременно. Стратегия ориентирована на высокую интенсивность торговли, высокую прибыль. При этом, ожидайте значительных колебаний и возможных просадок до 30% депозита в моменте. Однако, среднесрочно стратегия демонстрирует высокий винрейт, который компенсирует потенциальные убытки и приносит значимую прибыль.",
    highRisk: "Высокий уровень риска.",
    predictedProfit3: "Прогнозируемая прибыльность до ~30% в месяц.",
    recommendedForHighRisk:
      "Рекомендуется для тех, кто готов к значительному риску, но не рекомендуется для больших депозитов.",
  },
  differencesTitle: "Различия",
  investmentMethodD: {
    differences: "Различия методов инвестирования",
    investmentMethodsDescription:
      "Методы инвестирования на финансовых рынках могут значительно различаться в зависимости от предпочтений инвестора в отношении рисков и ожидаемой доходности. В данном случае речь идет о трех методах инвестирования, предлагаемых сервисом &Volume.",
    conservativeMethodDescription:
      "Консервативный метод подразумевает осторожное взаимодействие с рынком и минимизацию рисков. &Volume использует небольшие суммы для каждой инвестиции и стремится максимально ограничить убытки. Ожидаемая доходность при этом методе не превышает 10% в месяц, и количество сделок не превышает 5 в день. Этот метод рекомендуется для инвесторов с большими суммами, которые не хотят рисковать.",
    moderateMethodDescription:
      "Умеренный метод предполагает более активное участие в рынке и использование больших сумм. Количество сделок может достигать 10, и, хотя количество неудачных инвестиций может увеличиться, ожидаемая доходность также возрастает.",
    aggressiveMethodDescription:
      "Агрессивный метод предназначен для опытных инвесторов, готовых к большим рискам и потенциальной высокой доходности. В этом режиме, &Volume может осуществлять большое количество сделок, использовать большие суммы и активно контролировать риски. Ожидаемая прибыль может быть значительно выше, но и риски возрастают.",
    conclusion: "Исходя из этого, можно сделать вывод:",
    conservativeMethodConclusion:
      "Консервативный метод подходит для тех, кто хочет минимизировать риски и не готов к активным действиям на рынке.",
    moderateMethodConclusion:
      "Умеренный метод инвестирования может быть более подходящей для тех, кто готов к большему риску и ожидает большей доходности.",
    aggressiveMethodConclusion:
      "Агрессивный метод предназначена для опытных инвесторов, которые готовы к высоким рискам в надежде на высокую доходность.",
  },
  tradeLimitTitle: "Торговые лимиты",
  tradeAmountLimit: {
    title: "Лимит суммы торгов",
    description1:
      "Лимит суммы торгов - это та сумма, которую Volume будет использовать для торговли парами криптовалют. Для каждого тарифа эта сумма разная.",
    exampleTitle: "Пример:",
    exampleDescription:
      "В тарифе 'Стартовый' эта сумма составляет $400. Это значит, что Volume будет использовать в торговле $400, даже если общий размер вашего депозита на криптовалютной бирже составляет, к примеру, $10 000 или больше.",
    exampleContinuation:
      "Предположим, что после успешного месяца ваш баланс увеличился до $600. Тем не менее, используя тариф 'Стартовый', в торговле все равно будет использоваться сумма в $400.",
    increaseAmountDescription:
      "Чтобы увеличить сумму, доступную для торговли, вы можете переключиться на более высокий тариф. Это гибкая система, которая позволяет пользователям выбирать оптимальный лимит суммы торгов в зависимости от своих инвестиционных целей и степени комфорта.",
    startTariffName: "Стартовый",
  },
  topup_menu: "Покупка и продажа криптовалюты",
  repl_method: "Способы пополнения",
  depositMethods: {
    title: "Способы пополнения биржевого счета",
    description:
      "Существует несколько способов пополнения биржевого счета на криптовалютных биржах:",
    method1: {
      title: "Перевод криптовалюты",
      description:
        "На большинстве бирж можно перевести криптовалюту с одного адреса на другой. Для этого нужно знать адрес кошелька получателя и иметь доступ к приватному ключу своего кошелька.",
    },
    method2: {
      title: "Банковский перевод",
      description:
        "Некоторые биржи позволяют пополнять счет через банковский перевод. Однако этот способ может быть медленным и дорогостоящим.",
    },
    method3: {
      title: "Платежные сервисы",
      description:
        "Некоторые биржи поддерживают работу с платежными системами, такими как PayPal, Skrill и др.",
    },
    method4: {
      title: "P2P торговля",
      description:
        "Некоторые биржи предлагают возможность покупки криптовалюты у других пользователей через P2P торговлю.",
    },
    conclusion:
      "Каждый способ имеет свои преимущества и недостатки, поэтому выбор зависит от конкретной биржи и потребностей пользователя.",
  },
  ptop: "P2P-торговля",
  purchaseUSDT: {
    title: "Покупка криптовалюты USDT на Binance или ByBit через P2P",
    binance: "Для покупки USDT на Binance через P2P, следуйте этим шагам:",
    binanceStep1:
      "Войдите в свою учетную запись Binance и перейдите в раздел 'Торговля' (Trade).",
    binanceStep2:
      "Выберите вкладку P2P (Person to Person) и выберите рынок, на котором вы хотите совершить сделку (например, RUB, USD, EUR).",
    binanceStep3:
      "Введите сумму в рублях, долларах, евро, которую вы хотите потратить на покупку USDT.",
    binanceStep4:
      "Выберите продавца, который предлагает наиболее выгодную сделку для вас. Обратите внимание на отзывы других пользователей и рейтинг продавца.",
    binanceStep5: "Подтвердите сделку, следуя инструкциям на экране.",
    bybit:
      "Для покупки USDT на ByBit через P2P процесс аналогичен, но вам нужно перейти в раздел P2P Trading на их веб-сайте или мобильном приложении.",
  },
  risks: {
    title: "Risks",
    intro:
      "When trading cryptocurrency, there are several potential risks that investors should consider. &Volume strives to minimize your risks, but there are areas beyond our control.",
    marketVolatility: {
      title: "Market Volatility",
      description:
        "The cryptocurrency market is highly volatile. Prices of cryptocurrencies can change rapidly within short timeframes (minutes, hours). Our system reacts to the current situation, not future predictions.",
      consequences:
        "Consequences: The potential for significant profits is also associated with the risk of significant losses.",
    },
    marketConditions: {
      title: "Market Conditions",
      description:
        "Market conditions can significantly impact cryptocurrency prices. This includes news, regulations, technological changes, and other factors that are not 100% predictable.",
      consequences:
        "Consequences: Negative events in the market can lead to a decrease in cryptocurrency prices and losses for investors.",
    },
    technical: {
      title: "Technical Risks",
      description:
        "Technical issues, cyberattacks, security problems, and other technical failures can affect the functioning of cryptocurrency exchanges and wallets.",
      consequences:
        "Consequences: Loss of access to funds, leakage of personal information, and other negative consequences.",
    },
    regulatory: {
      title: "Regulatory Risks",
      description:
        "Changes in cryptocurrency legislation and regulations in various countries can impact the legality and status of cryptocurrencies. Stay updated on news in your country.",
      consequences:
        "Consequences: Introduction of restrictions, bans, or changes in legislation can affect cryptocurrency trading and ownership.",
    },
    partner: {
      title: "Partner Risks",
      description:
        "Risk related to the reliability of counterparts, such as exchanges or other trading platforms.",
      consequences:
        "Consequences: Issues with fund withdrawal, unavailability of the exchange.",
    },
    liquidity: {
      title: "Liquidity",
      description:
        "Lack of liquidity in the market can make it difficult to execute trades at desired prices.",
      consequences:
        "Consequences: Significant discrepancies between expected prices and actual prices when executing trades.",
    },
    investorsShouldBeAware:
      "Investors should be aware of these risks and take measures to mitigate them, regardless of market conditions.",
  },
  risks: {
    title: "Риски",
    intro:
      "При торговле криптовалютой существует ряд потенциальных рисков, которые инвесторы должны учитывать. &Volume старается минимизировать ваши риски, однако есть области вне нашего контроля.",
    marketVolatility: {
      title: "Волатильность рынка",
      description:
        "Рынок криптовалюты очень волатилен. Цены на крипто могут резко изменяться в очень короткие промежутки времени (минуты, часы). Наша система руководствуется текущей ситуацией, а не прогнозирует будущее.",
      consequences:
        "Последствия: Возможность больших прибылей также сопряжена с риском значительных потерь.",
    },
    marketConditions: {
      title: "Рыночный риск",
      description:
        "Рыночные условия могут существенно влиять на цены криптовалют. Это включает в себя новости, регулирование, технологические изменения и другие факторы, которые не поддаются 100%-му прогнозированию.",
      consequences:
        "Последствия: Негативные события на рынке могут привести к снижению стоимости криптовалют и убыткам для инвесторов.",
    },
    technical: {
      title: "Технологические риски",
      description:
        "Технические проблемы, кибератаки, проблемы безопасности и другие технические сбои могут повлиять на функционирование криптовалютных бирж и кошельков.",
      consequences:
        "Последствия: Потеря доступа к средствам, утечка личной информации, а также другие негативные последствия.",
    },
    regulatory: {
      title: "Регуляторные риски",
      description:
        "Изменения в законодательстве и регулировании криптовалют в различных странах могут повлиять на легальность и статус криптовалют. Следите за новостями в вашей стране.",
      consequences:
        "Последствия: Введение ограничений, запретов или изменений в законодательстве может повлиять на торговлю и владение криптовалютами.",
    },
    partner: {
      title: "Партнерский риск",
      description:
        "Риск связанный с ненадежностью контрагентов, таких как биржи или другие торговые платформы.",
      consequences:
        "Последствия: Проблемы с выводом средств, недоступность биржи.",
    },
    liquidity: {
      title: "Ликвидность",
      description:
        "Отсутствие ликвидности на рынке может затруднить исполнение сделок по желаемым ценам.",
      consequences:
        "Последствия: Значительные расхождения между ценами ожидания и фактическими ценами при совершении сделок.",
    },
    investorsShouldBeAware:
      "Инвесторы должны осознавать эти риски и принимать меры по их снижению, вне зависимости от условий рынка.",
  },
  timeFrame: {
    today: "За сегодня",
    last7Days: "За последние 7 дн.",
    last30Days: "За последние 30 дн.",
    last90Days: "За последние 90 дн.",
    allTime: "За все время",
  },
  pnl_no_data: "Нет данных по Pnl",
  not_have: "Не указано",

  one_year: "1 год",
  six_month: "6 месяцев",
  three_month: "3 месяца",
  one_month: "1 месяц",
  labels: {
    timeAdded: "Время добавления",
    exchange: "Биржа",
  },
  apiRecNot: {
    text1: "У вас не подключены API-ключи. Чтобы продолжить,",
    text2: " необходимо добавить подключение.",
  },
  apiADittionals: {
    question: "Как подключить?",
    binanceQuest: "Нет аккаунта на Binance?",
    create: "Создать сейчас",
    bybitQuest: "Нет аккаунта на Bybit?",
  },
  login: {
    log_title: "Войти",
    incorrectEmail: "Неправильный email",
    em_pass: "Введите email и пароль",
    email: "Электронная почта",
    password: "Пароль",
    pass_forgot: "Забыли пароль",
    no_acc: "Еще нет аккаунта?",
    create_acc: "Создать аккаунт",
    rules: "Продолжая регистрацию или вход, вы принимаете условия",
    agreement: "Пользовательское Соглашение",
    policy: "Политики конфиденциальности.",
    log_error:
      "Ошибка электронной почты или пароля. Проверьте правильность введенных данных.",
  },
  canceled: "Отменен",
  auth: {
    error:
      "Кажется, вы уже зарегистрированы в нашей системе. Пожалуйста, нажмите войти.",
    error2: "Имя и фамилия должны быть не менее 3 символов",
    title: "Регистрация",
    referal: "Реферальный код (необязательно)",
    datas: "Введите данные",
    have_acc: "Уже есть аккаунт?",
  },
  createPassword: "Создать пароль",
  labels2: {
    password: "Пароль",
    passwordRequirements: "Ваш пароль должен содержать:",
    passwordLength: "Длина 8-32 символа",
    confirmPassword: "Пароль ещё раз",
    uppercase: "1 символ верхнего регистра",
    lowercase: "1 символ нижнего регистра",
    number: "1 цифра",
  },
  buttons: {
    next: "Далее",
  },
  resetPassword: "Сбросить пароль",

  invalidEmail: "Неправильный email",
  getConfirmationCode: "Получить код подтверждения",
  resendCode: "Отправить повторно ",
  passwordChanged: "Пароль изменен",
  passwordChangedMessage: "Ваш пароль успешно изменен",
  select_date: "Выберите дату",
  months: {
    1: "Январь",
    2: "Февраль",
    3: "Март",
    4: "Апрель",
    5: "Май",
    6: "Июнь",
    7: "Июль",
    8: "Август",
    9: "Сентябрь",
    10: "Октябрь",
    11: "Ноябрь",
    12: "Декабрь",
  },
  check: "Проверить",
  error_code:
    "Ошибка проверочного кода. Проверьте правильность введенных данных.",
  passwordChangeSuccess: "Ваш пароль успешно изменен.",
  passwordChangeError:
    "Неверный старый пароль. Проверьте правильность введенных данных.",
  supported: "Поддерживается",
  modals: {
    api: "API",
    addedTime: "Время добавления",
    exchange: "Биржа",
    edit: "Редактировать",
    delete: "Удалить",
  },
  tg_chat: "Telegram чат",
  tariff: {
    title: "Купить тарифный план",
    descr:
      "Купить тарифный план, чтобы продолжить использование сервиса &Volume",
    button: "Купить тариф",
  },
  not_found: {
    title: "Ой....",
    p: "Страница не найдена",
    desc: "Эта страница не существует или была удалена! Мы предлагаем вам вернуться обратно.",
    button: "Вернуться обратно",
  },
  all_balance: "Использовать все",
  mon: "мес",
  days: "дней",
  help: {
    help_title: "Помощь",
    help1_title: "Купить тарифный план",
    help1_desc:
      "Купить тарифный план, чтобы продолжить использование сервиса &Volume",
    help1_btn: "Купить тариф",
    help2_title: "Подключите API ключи",
    help2_desc:
      "Это нужно для того, чтобы ваш алгоритм мог совершать торговые операции на вашем аккаунте.",
    help2_btn: "Подключить API ключи",
    help3_title: "Создайте алгоритм",
    help3_desc:
      " Создайте алгоритм и начните зарабатывать вместе с сервисом &Volume",
    help3_btn: "Создать алгоритм",
    did_btn: "Выполнено",
  },
  api_keys_desc: "Здесь отображаются активные подключения",
  warning_del: {
    title: "Предупреждение",
    desc_api:
      "Если у вас есть активные сделки, удаление API ключей может привести к их автоматическому закрытию и удалению алгоритма. Вы уверены в том, что хотите удалить API ключи?",
    desc_algo:
      "Если у вас есть активные сделки, удаление алгоритма может привести к их автоматическому закрытию. Вы уверены в том, что хотите удалить алгоритм?",
    cancel: "Отмена",
    delete: "Удалить",
  },
};
